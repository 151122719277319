
/* eslint-disable */
import CloudFun, { computed, defineComponent, ref, Condition, Operator, reactive, onMounted } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import CheckBoxList from '@/cloudfun/components/CheckBoxList.vue'
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import { getformOption, getType } from '@/libs/formItems';
import InvoiceQuarterlyItemTable from "@/components/document/InvoiceQuarterlyItemTable.vue";
import BOMUsageQuantityModifyItemTable from "@/components/document/BOMUsageQuantityModifyItemTable.vue";
import MaterialSubstitutesItemsTable from "@/components/document/MaterialSubstitutesItemsTable.vue";
import AssessmentItemsTable from "@/components/document/AssessmentItemsTable.vue";
import ProductNumberModifuItemsTable from "@/components/document/ProductNumberModifuItemsTable.vue";
import PurchaseRequisitionItemsTable from "@/components/document/PurchaseRequisitionItemsTable.vue";
import OrderItemsTable from "@/components/document/OrderItemsTable.vue";
import CustomerCreditItemsTable from "@/components/document/CustomerCreditItemsTable.vue";
import InProgressFlowTable from "@/components/document-flow/InProgressFlowTable.vue";
import DocumentFileUpload from "@/components/file/DocumentFileUpload.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    InvoiceQuarterlyItemTable,
    BOMUsageQuantityModifyItemTable,
    MaterialSubstitutesItemsTable,
    AssessmentItemsTable,
    ProductNumberModifuItemsTable,
    PurchaseRequisitionItemsTable,
    OrderItemsTable,
    CustomerCreditItemsTable,
    InProgressFlowTable,
DocumentFileUpload,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [{ field: 'Name' }, { field: 'Description' }]
    const selectedType = ref<any>('');

    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;

    const checkPermissions = (key: string) => {
      // console.log(user?.Permissions)
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const canCreate = checkPermissions("DocumentFlowCreate");
    const canUpdate = checkPermissions("DocumentFlowUpdate");
    const canDelete = checkPermissions("DocumentFlowDelete");

    const gridOptions: GridOptions = {
      id: 'document',
      title: '離職申請與移交確認紀錄',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canCreate: canCreate,
      canUpdate: canUpdate,
      canDelete: canDelete,
      multiselect: false,
      printConfig: {
        sheetName: '清單',
        columns: printColumns,
        modes: ['current', 'selected', 'all']
      },
      exportConfig: {
        filename: '清單',
        type: 'csv',
        types: ['html', 'csv'],
        mode: 'all',
        modes: ['current', 'selected', 'all'],
        columns: printColumns
      },
      columns: [
        // { field: 'Title', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true },
        // { field: "Type", title: "文件類型", showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.DocumentType).find(e => e.Value === cellValue)?.Name : undefined },
        // { field: "ContentType", title: "表單類型", showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.DocumentContentType).find(e => e.Value === cellValue)?.Name : undefined },
        { field: 'Submitter.Person.Name', title: '申請人', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'CurrentSignatureLevel', title: '目前簽核層級', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Status', title: '簽核狀態', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.DocumentStatus).find(e => e.Value === cellValue)?.Name : undefined },
        {
          title: '文件流程',
          width: '100',
          slots: { default: "flow" }, align: 'center'
        },
      ],
      promises: {
        query: model ? async (params) => {          
          params.condition!.and("ContentType", Operator.Equal, 38);
          if (params?.sortings?.length === 0) {
            params.sortings = [{ column: 'CreatedTime', order: 1 }];
          }
          if(user && !user.IsAdmin && !user.isUnitManager && !user.isDepManager && !user.isHR) {
            params.condition = new Condition("SubmitterId.Value", Operator.Equal, user.Id).and(params.condition!);
          }
          if(user && user.userDepartmentId && (user.isUnitManager || user.isDepManager) && !user.isHR) {
            console.log('user-userDepartmentId', user.userDepartmentId);
            params.condition = new Condition("Submitter.DepartmentId", Operator.Equal, user.userDepartmentId).and(params.condition!);
          }
          return await model.dispatch('document/query', params);
        } : undefined,
        queryAll: model ? () => model.dispatch('document/query') : undefined,
        save: model
          ? (params) => model.dispatch('document/save', params)
          : undefined
      },
      modalConfig: { width: "60%", showFooter: true, fullscreen: true }
    }

    const form = ref<any>();
    const readType = ref(false);
    const defaultFormOptions: VxeFormProps = {
      items: [
        // {
        //   field: 'Title',
        //   title: '名稱',
        //   span: 12,
        //   itemRender: {
        //     name: '$input',
        //     props: { placeholder: '請輸入文字' },
        //     attrs: { type: 'text' }
        //   }
        // },
        {
          field: 'SubmittingTime',
          title: '提交時間',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        // {
        //   field: 'Type',
        //   title: '文件類型',
        //   span: 12,
        //   itemRender: { name: "$select", props: {}, options: model ? Object.values(model.enums.DocumentType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        // },
        // {
        //   field: 'ContentType',
        //   title: '表單類型',
        //   span: 12,
        //   itemRender: { name: "$select", props: { disabled: true }, options: model ? Object.values(model.enums.DocumentContentType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        // },
        {
          field: 'SubmitterId',
          title: '申請人',
          span: 12,
          slots: { default: "column-submitter" }
        },
        // {
        //   field: 'CurrentSignatureLevel',
        //   title: '目前簽核層級',
        //   span: 12,
        //   itemRender: {
        //     name: '$input',
        //     props: { placeholder: '', type: 'number', disabled: true },
        //   }
        // },
        // {
        //   field: 'Status',
        //   title: '簽核狀態',
        //   span: 12,
        //   itemRender: { name: "$select", props: { disabled: true }, options: model ? Object.values(model.enums.DocumentStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        // },
      ],
      rules: {
        Title: [{ type: 'string', required: true }],
        SubmittingTime: [{ required: true }],
        Type: [{ required: true }],
        ContentType: [{ required: true }],
      }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',      
    }

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Person.Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("employee/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("employee/query", params) // eslint-disable-line
        }
      }
    };

    const departmentSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("department/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("department/query", params) // eslint-disable-line
        }
      }
    };

    const initAddData = async (row: any, callback: any) => {
      row.SubmitterId = user?.Id;
      row.SubmittingTime = new Date();
      row.Type = getType(38);
      row.Title = "離職申請與移交確認紀錄";
      row.Status = 0;
      readType.value = false;
      row.ContentType = 38;
      row.XmlData = {} as any;
      row.XmlData.InvoiceQuarterlyItems = [] as any;
      row.XmlData.Name = user?.Name;
      formOptions.items = defaultFormOptions.items;
      formOptions.rules = defaultFormOptions.rules;
      var contentTypeOption = getformOption(row.ContentType, model, row.Status == 0 ? false : true) as any;      
      let defaultCopy = JSON.parse(JSON.stringify(defaultFormOptions));      
      Array.prototype.push.apply(defaultCopy.items, contentTypeOption.items);
      for (const key in contentTypeOption.rules) defaultCopy.rules[key] = contentTypeOption.rules[key];
      formOptions.items = defaultCopy.items;
      formOptions.rules = defaultCopy.rules;
      grid.value.isModalPopup = true;
      await changeUser(row);
      callback();
    }

    const setDefaultReadOnly = (data: any) => {
      return data;
    }

    const onGridEdit = async (row: any, callback: any) => {
      const entity = row.Id ? await model?.dispatch('document/find', row.Id) : undefined
      Object.assign(row, entity);
      if (row.Id) {
        readType.value = false;
        var contentTypeOption = getformOption(row.ContentType, model, row.Status == 0 ? false : true) as any;
        let defaultCopy = JSON.parse(JSON.stringify(defaultFormOptions));
        if(row.Status != 0) { //唯讀
          readType.value = true;
          defaultCopy = setDefaultReadOnly(defaultCopy);
        }
        Array.prototype.push.apply(defaultCopy.items, contentTypeOption.items);
        for (const key in contentTypeOption.rules) {
          defaultCopy.rules[key] = contentTypeOption.rules[key];
        }        
        formOptions.items = defaultCopy.items;
        formOptions.rules = defaultCopy.rules;
        fileModal.show(row);
      }
      callback()
    }

    const itemsTable = ref<any>();
    const ProductModifyItemsTable = ref<any>();
    const FinishedProductModifyItemsTable = ref<any>();
    const ReRollModifyItemsTable = ref<any>();
    const MaterialSubstitutesItemsTable = ref<any>();
    const AssessmentItemsTable = ref<any>();
    const ProductNumberModifuItemsTable = ref<any>();
    const PurchaseRequisitionItemsTable = ref<any>();
    const OrderItemsTable = ref<any>();
    const CustomerCreditItemsTable = ref<any>();

    const formSubmit = (row: any, submit: any) => {
      submit();
    }

    const exportReport = (row: any) => {
      //替換API_URL
      window.open(`https://report.imp.cloudfun.app/api/ReportView/DocumentReport?docId=${row.Id}`)
    }

    onMounted(() => {
      selectedType.value = 38;
    })

    /** 資訊需求申請使用 */
    const typesSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.ITRequirementItem).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.ITRequirementItem).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };
    const reasonsSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.ITRequirementICauseReason).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.ITRequirementICauseReason).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };
    const annexSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.ITRequirementIAnnex).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.ITRequirementIAnnex).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const wrokOrderItemsSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.WorkOrderType).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.WorkOrderType).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const applicationTypesSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.PaymentApplicationType).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.PaymentApplicationType).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const purchaseRequisitionTypeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.PurchaseRequisitionType).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.PurchaseRequisitionType).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    /** 權限開放申請單使用 */
    const leavingReasonTypeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.LeavingReasonType).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.LeavingReasonType).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const sendDocument = async (row: any) => {
      if(row.Id && confirm("確定要送出文件嗎?")) {
        try {
          await model?.dispatch('document/send', row.Id);
          CloudFun.send("info", {
            subject: "執行成功",
            content: `已送出文件`,
          });
          grid.value.isModalPopup = false;
          grid.value.editingRow = null;
          grid.value.refresh();
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      }
    }

    const fileGrid = ref<any>()
    const fileModal = reactive({
      visible: false,
      selectedRow: {} as any,
      uploadOptions: {
        promises: {
          async query() {
            var condition = new Condition("DocumentId", Operator.Equal, fileModal.selectedRow.Id.toString());
            const sortings = [{ column: "CreatedTime", order: 0 }];
            return await model?.dispatch('files/query', {
              condition: condition,
              sortings: sortings,
            });
          },
          insert(formData: FormData) {
            return model?.dispatch('files/insert', { formData, mode: 'other', entityId: 'Document_' + fileModal.selectedRow.Id, fileType: 2 });
          },
          delete(id: number) {
            return model?.dispatch('files/delete', id);
          }
        }
      },
      show(row: any) {
        fileModal.selectedRow = row;
        if (fileGrid.value) fileGrid.value.refresh();
      }
    })

    const flowGrid = ref<any>()
    const flowModal = reactive({
      visible: false,
      selectedRow: {} as any,
      gridOptions: {
        promises: {
          query: model ? (params) => {
            params.condition = new Condition("DataMode", Operator.Equal, 0).and("DocumentId", Operator.Equal, flowModal.selectedRow.Id).and("NotifyOnly", Operator
              .Equal, false
            )
              .and(params.condition!);
            if (!params.sortings || params.sortings?.length === 0) {
              params.sortings = [{ column: "Level", order: 0 }, { column: "CreatedTime", order: 0 }];
            }
            // var selectedStatus = flowGrid?.value.getSelectedStatus();
            // if (selectedStatus != null) {
            //   params.condition.and("Status", Operator.Equal, selectedStatus)
            // }
            return model.dispatch("inProgressFlow/query", params);
          } : undefined,
          queryAll: model ? () => model.dispatch('inProgressFlow/query') : undefined,
          save: model ? (params) => model.dispatch('inProgressFlow/save', params) : undefined
        }
      } as GridOptions,
      show(row: any) {
        flowModal.visible = true;
        flowModal.selectedRow = row;
        if (flowGrid.value) flowGrid.value.refresh();
      }
    })

    const userSelector = async (data: any) => {
      console.log('----');
      console.log(data);
      if(data?.Name) {
        try { 
          data.XmlData.HandoverName = data.Name;
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      }
    }

    const changeUser = async (row: any) => {
      if (row && row.SubmitterId) {
        const result = await model!.dispatch("employee/find", row.SubmitterId);
        row.XmlData.EmployeeName = result.Person.Name;
        row.XmlData.Name = result.Person.Name;
        row.XmlData.EmployeeNo = result.Number;
        row.XmlData.DepartmentId = result.DepartmentId;
        row.XmlData.Position = result.Position;
        row.XmlData.Signature_Employee = result.Person.Name;
        row.XmlData.OnBoardDate = result.InsuranceDate;
        row.XmlData.StartReviewDate = result.InsuranceDate;
        row.Title = result.Person.Name + "-離職申請與移交確認紀錄";
        // const insuranceDate =new Date(result.InsuranceDate);
        // insuranceDate.setMonth(insuranceDate.getMonth() + 3);
        // insuranceDate.setDate(insuranceDate.getDate() - 1);
        // row.XmlData.EndReviewDate = insuranceDate
        //row.XmlData.DepartmentId = 
      }
    };

    return {
      canCreate,
      grid,
      form,
      gridOptions,
      formOptions,
      userSelectorOptions,
      departmentSelectorOptions,
      selectedType,
      model,
      initAddData,
      onGridEdit,
      itemsTable,
      formSubmit,
      readType,
      annexSelectorOptions,
      reasonsSelectorOptions,
      typesSelectorOptions,
      wrokOrderItemsSelectorOptions,
      ProductModifyItemsTable,
      ReRollModifyItemsTable,
      FinishedProductModifyItemsTable,
      MaterialSubstitutesItemsTable,
      applicationTypesSelectorOptions,
      AssessmentItemsTable,
      ProductNumberModifuItemsTable,
      PurchaseRequisitionItemsTable,
      purchaseRequisitionTypeSelectorOptions,
      OrderItemsTable,
      CustomerCreditItemsTable,
      leavingReasonTypeSelectorOptions,
      sendDocument,
      flowGrid,
      flowModal,
      fileGrid,
      fileModal,
      exportReport,
      userSelector,
      changeUser
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
